import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "flowbite";
import $ from 'jquery/src/jquery';
import debounce from 'lodash/debounce';


Rails.start()
Turbolinks.start()
ActiveStorage.start()

$(document).on('turbolinks:load', function() {
  $('#default-search').on('change', function() {
    $(this).closest('form').trigger('submit.rails') ;
  });
  $('#default-search').on('input', function(e) {
    if ($(this).val() === '') {
      $(this).closest('form').trigger('submit.rails');
    }
  });

  $('a[data-article-id]').on('click', function(event) {
    event.preventDefault();
    var articleId = $(this).data('article-id');
    var url = $(this).attr('href');

    $.ajax({
      url: '/articles/' + articleId + '/increment_visit_count',
      type: 'POST',
      headers: {
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      },
      success: function() {
        window.open(url, '_blank');
      }
    });
  });

  document.querySelectorAll('.carousel-image-link').forEach(link => {
    link.addEventListener('click', function(event) {
      event.preventDefault(); // Prevent default link behavior

      const imageId = this.dataset.id;

      // Send AJAX request to increment clicks
      fetch(`/ad_images/${imageId}/increment_clicks`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
        },
        credentials: 'same-origin'
      })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          console.log(`Click count for image ${imageId}: ${data.clicks_count}`);
          // Optionally redirect or perform other actions here
          window.open(this.href, '_blank'); // Redirect after incrementing clicks
        }
      })
      .catch(error => console.error('Error:', error));
    });
  });

  // Scrolling smoothly to the highlighted article on page load
  document.addEventListener("DOMContentLoaded", function () {
    const highlighted = document.querySelector(".highlighted-news");
    if (highlighted) {
      highlighted.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  });
});
